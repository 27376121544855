<template>
  <section class="privacy_page business_cooperation">
    <h1>{{ $t('about-us.key003') }}</h1>
    <p>{{ $t('business-cooperation.key001') }}</p>
    <ul>
      <li>
        <span>{{ $t('business-cooperation.key002') }}</span
        >｜{{ $t('business-cooperation.key003') }}
      </li>
      <li>
        <span>{{ $t('business-cooperation.key004') }}</span> ｜{{
          $t('business-cooperation.key005')
        }}
      </li>
      <li>
        <span>{{ $t('business-cooperation.key006') }}</span
        >｜{{ $t('business-cooperation.key007') }}
      </li>
    </ul>
    <p v-html="formattedKey008"></p>
  </section>
</template>
<script>
export default {
  name: 'LegalPage',
  components: {},
  mounted() {},
  data() {
    return {};
  },
  props: {},
  computed: {
    formattedKey008() {
      return this.$t('business-cooperation.key008', {
        mailtoVIVE_ORIGINALS: `<a href="mailto:VIVE_ORIGINALS@HTC.COM">VIVE_ORIGINALS@HTC.COM</a>`,
      });
    },
  },
  methods: {},
};
</script>

<style lang="scss"></style>
