<template>
  <app-header></app-header>
  <section class="privacy_page return-policy-MizukiLin wrapper">
    <div class="wpb_wrapper">
      <div class="wpb_wrapper">
        <h1>Annin Miru Wonderland 週末 Party Night！</h1>
        <h2>銷售條款</h2>
        <ul>
          <li>購買前，請務必完整閱讀BEATDAY之服務條款與隱私權政策，並確認您的電腦或裝置符合BEATDAY系統、相容性要求。</li>
          <li>請詳閱本《銷售條款》及「商品資訊」後，再行購買，一旦進行購買/退款作業流程，即視同您已瞭解並同意遵守本《銷售條款》及該商品相關購買/退款事項規定。</li>
        </ul>
        <h2>購買須知</h2>
        <p>1. 購買程序</p>
        <p>
          請您依照網頁上的程序操作，若因資料填寫錯誤、不完整或隱匿真實資料，導致訂購失敗、不能領票、不能入場或無法退票等狀況，您需自行承擔因提供錯誤資訊所衍生的風險或造成的結果。
        </p>
        <p>2. 付款及確認</p>
        <p>2.1 請務必確實核對您所訂購的內容是否正確，再行付款。</p>
        <p>
          2.2 若您以信用卡方式付款，購票系統將即刻進入信用卡授權作業，此時請勿關機或切換畫面，線路忙碌時可能等待超過 60
          秒，敬請耐心等候。訂購成功且確認款項已入帳，系統將以電子郵件通知訂購成立資訊（會員信箱如為免費信箱極有可能無法收信），訂購完畢後請務必至信箱確認是否有訂購資料，以免發生重複訂票之情形。
        </p>
        <p>2.3 訂單成立後請於48小時內完成付款，逾期未付款完成，訂單將會自動取消並釋出商品，如需購買請再重新訂購。</p>
        <p>3. 取票須知</p>
        <p>
          3.1
          成功購買電子票券後，您將由電子郵件獲得「取票序號」及操作指示。您須於BEATDAY官網註冊並登入會員後，始得進行序號兌換，序號兌換後可獲得相關票券或道具，並可於指定時間入場觀賞演出。
        </p>
        <p>
          3.2 每一個取票序號僅限兌換使用一次，且取票序號兌換後即綁定指定的 BEATDAY
          帳號。不論帳號是否屬於同一人，取票序號皆不可重複使用，也無法轉移或贈與至其他帳號。請注意，如以不同第三方帳號（例如：Facebook 及 Google 等）登入BEATDAY
          平台時，均視為不同帳號，於購買及兌換活動票券前，請您務必確認實際使用的帳號。
        </p>
        <p>3.3 電子票券一經傳輸至使用者之個人電腦或手持行動裝置上，其使用等相關權益，即依 BEATDAY 官網之相關規定辦理。</p>
        <p>4. 觀賞注意事項</p>
        <p>
          4.1 本次活動為線上Live Show，請至少於開演前一天下載 BEATDAY
          電腦版程式與更新資源包，並提前登入熟悉操作與測試電腦規格。開演當日請務必提早登入並入場等候表演開始，若晚於預定時間進入，將無法看到完整演出。
        </p>
        <p>4.2 票券有效狀態依系統驗證結果為準，BEATDAY擁有最終解釋權。</p>
        <p>4.3 其他條件或限制，依票券內容規定。</p>
        <h2>退換貨須知</h2>
        <ul>
          <li>實體商品有瑕疵方可申請換貨。</li>
          <li>如購買套票組合，退換商品必須整組套票退換，無法退訂單張票券或僅退訂部分商品。</li>
        </ul>
        <p>1. 除非政府另有公告優先適用其他法令，消費者於 BEATDAY 官網購買商品享有7日猶豫期，您可於收到商品7日內辦理退貨。</p>
        <p>2. 請注意猶豫期並非試用期，退回商品必須是全新狀態且包裝完整（含所有附件、附隨文件，例如發票），否則恕不接受退訂。</p>
        <p>3. 退票限制：請注意取票序號一經兌換，或收到取票序號逾7日未辦理退票者，恕不接受退票。</p>
        <p>4. 實體商品換貨：除非您收到的商品有瑕疵或受損、或為不正確商品，方可申請辦理換貨外，恕不接受換貨，請於猶豫期內辦理退貨。</p>
        <p>5. 一般退票：退票需由購票者向BEATDAY LINE@客服主動提出。</p>
        <p>
          6.
          不可抗力因素之退票：如因國內、外重大事件、自然非人為因素或活動表演人之各種狀況，導致演出取消或延期，BEATDAY將於確認退票退款規則後，以適當方式通知並公告於官方社群平台；後續退票或其他事宜，請參見官方社群平台說明。
        </p>
        <p>7. 倘購買套票組合（含贈品、虛擬或實體周邊商品），退換商品需整體套票組合申請辦理退換，無法退訂單張票券或僅退訂部分商品。</p>
        <p>8. 以現金付款或虛擬帳號匯款之交易訂單，退款一律為匯款方式；以信用卡交易付款之交易訂單，退款一律為退刷方式。</p>
        <p>9. 有關退換貨申辦，您須提供有效聯絡方式，同意並授權BEATDAY全權處理銷貨退回、折讓證明單或電子發票折讓等處理。</p>
        <p>10. 訂單經退訂後即無法復原，敬請再次確認。</p>
        <h2>注意事項</h2>
        <p>1. 周邊商品圖片為參考示意圖，依實際商品為準。</p>
        <p>2. 實體商品之物流配送狀態，請您至物流平台查詢。</p>
      </div>
    </div>
  </section>
  <app-footer></app-footer>
</template>
<script>
// import { getSomething } from '@/api/apiService';
import AppHeader from '@/components/Header.vue';
import AppFooter from '@/components/Footer.vue';
export default {
  name: 'LegalPage',
  components: {
    'app-header': AppHeader,
    'app-footer': AppFooter,
  },
  mounted() {},
  data() {
    return {};
  },
  props: {},
  computed: {},
  methods: {
    // async fetchData() {
    //     try {
    //         const response = await getSomething();
    //         this.test = response;
    //     } catch (error) {
    //         // Handle error
    //     }
    // },
  },
};
</script>

<style lang="scss"></style>
