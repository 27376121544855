<template>
  <div ref="myModal" @click="checkClose($event)" id="myModal" class="modal announce">
    <div class="mask"></div>
    <div class="modal-content" @click.stop>
      <h1>重要通知</h1>
      <h3>Major Announcement</h3>
      <div class="driver"></div>
      <div class="content">
        <p>由於〈鏡花水月〉最終的幻歌祭演唱會節目進行當下，因網路連線不穩以致演出中斷，BEATDAY 對此進行相應調整措施並將同步更新於官方社群，完整訊息請參閱社群平台最新公告。</p>
        <div class="FB">
          <a href="https://www.facebook.com/beatday.music">前往社群</a>
        </div>
        <p class="en_text">
          Regrettably, unforeseen circumstances arose during the Final Illusion, resulting in an interruption to the performance. BEATDAY has promptly enacted necessary adjustments
          and will disseminate updates through its official social media channels. For comprehensive details, kindly consult the latest announcement posted on our social media
          platforms.
        </p>
        <div class="FB">
          <a href="https://www.facebook.com/beatday.music">BEATDAY Facebook</a>
        </div>
      </div>
      <!-- <div class="flexcon">
                <div class="left_flex">
                    <p>
                        親愛的《鏡花水月》粉絲您好：</p>
                    <p>
                        首先我們要對您表達最深的歉意。</p>
                    <p>10/13(五)晚間8點播出的《鏡花水月
                        Illusionary》Vtuber演唱會，由於在線人數瞬間暴增，導致部分觀眾出現了卡頓與白畫面情況。於此，我們對於偶像和觀眾們造成的所有不便，深深表達歉意。
                        我們將無條件在公開平台上以限時觀看的方式公開首日(10/13)演唱會表演，具體平台及時間將另行公告。</p>
                    <p>
                        同時，為了補償各位的損失和確保之後的完整體驗，BEATDAY和春魚決定打造更完善的演出，並新增全新曲目與一場免費全粉絲封測演出。</p>
                    <p> 有鑑於此，10/14(六)、10/15(日)兩場演出將配合新歌演唱會的製作，日期將順延至2024年年初擴大舉辦。</p>
                    <p> 粉絲們將可持原有序號無條件參與所有場次；有疑慮的粉絲也請您放心，我們將全力協助您退票。</p>
                    <p>免費全粉絲封測演出則將在冬季預先舉辦，彌補粉絲的損失。</p>
                    <p>封測以及正式演出時間BEATDAY將進一步公布於官網與社群平台。
                        最後，再次向您致上衷心的歉意，並感謝您的諒解與支持！</p>
                    <p>封測以及正式演出時間BEATDAY將進一步公布於官網與社群平台。
                        最後，再次向您致上衷心的歉意，並感謝您的諒解與支持！</p>
                    <p>封測以及正式演出時間BEATDAY將進一步公布於官網與社群平台。
                        最後，再次向您致上衷心的歉意，並感謝您的諒解與支持！</p><br><br>

                </div>
            </div> -->

      <div class="close button_style_linear" @click="closeAlert">
        <h2 class="close_icon">關閉 &times;</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnnounceBox',
  data() {
    return {};
  },
  mounted() {
    document.body.style.overflow = 'hidden';
  },
  methods: {
    checkClose(event) {
      // 獲取 modal-content 元素的參考
      const modalContent = this.$refs.myModal.querySelector('.modal-content');

      // 檢查點擊的元素是否是 modal-content
      if (!modalContent.contains(event.target)) {
        this.closeAlert();
      }
    },
    closeAlert() {
      document.body.style.overflow = '';
      this.$emit('close-alert');
    },
  },
};
</script>
