<template>
  <app-header></app-header>
  <section class="Downloads_Tutorials">
    <section class="download_page">
      <h1>{{ $t('download.key001') }}</h1>
      <h2>{{ $t('download.key002') }}</h2>
      <div class="into">
        <div class="PCorMac">
          <div class="item">
            <h3>Windows</h3>
            <img src="../assets/img/faq/PC@150.webp" alt="" />
            <a :href="DOWNLOAD_WIN_url" class="button_style_linear" :class="{ disabled: !agreed }">
              {{ button_text }}
            </a>
          </div>
          <div class="item">
            <h3>MAC</h3>
            <img src="../assets/img/faq/MAC@150.webp" alt="" />
            <a :href="DOWNLOAD_MAC_url" class="button_style_linear" :class="{ disabled: !agreed }">
              {{ button_text }}
            </a>
          </div>
        </div>
        <div class="agreement_box">
          <p>
            <input type="checkbox" id="agreement" required v-model="agreed" @click="checkbox" />
            <label for="agreement">
              {{ $t('download.key003') }}
              <router-link :to="`/${currentLanguage}/legal/user-policy`" target="_blank">
                {{ $t('download.key004') }}
              </router-link>
              {{ $t('download.key005') }}
              <router-link :to="`/${currentLanguage}/legal/privacy`" target="_blank">
                {{ $t('download.key006') }}
              </router-link>
              {{ $t('download.key007') }}
            </label>
          </p>
          <ol>
            <li>{{ $t('download.key008') }}</li>
            <li>{{ $t('download.key009') }}</li>
          </ol>
        </div>
      </div>
      <h1>{{ $t('download.key010') }}</h1>
      <div class="into">
        <div class="norm">
          <div class="item">
            <h3>Windows</h3>
            <ul>
              <li>
                CPU：Intel® Core™ i5
                <br />
                (4th generation or better) or equivalent
              </li>
              <li>RAM：8GB</li>
              <li>{{ $t('download.key011') }}</li>
            </ul>
          </div>
          <div class="item">
            <h3>MAC</h3>
            <ul>
              <li>{{ $t('download.key012') }}</li>
              <li>{{ $t('download.key013') }}</li>
            </ul>
          </div>
        </div>
        <ul class="ul">
          <li>
            {{ $t('download.key014') }}
          </li>
          <li>{{ $t('download.key015') }}</li>
        </ul>
      </div>
    </section>
  </section>
  <app-footer></app-footer>
</template>
<script>
import AppHeader from '@/components/Header.vue';
import AppFooter from '@/components/Footer.vue';
export default {
  name: 'IndexPage',
  components: {
    'app-header': AppHeader,
    'app-footer': AppFooter,
  },
  mounted() {},
  data() {
    return {
      agreed: false,
      button_text: this.$t('download.key016'),
      DOWNLOAD_WIN_url: process.env.BEATDAY_DOWNLOAD_WIN,
      DOWNLOAD_MAC_url: process.env.BEATDAY_DOWNLOAD_MAC,
    };
  },
  props: {},
  computed: {},
  methods: {
    checkbox() {
      if (!this.agreed) {
        this.button_text = this.$t('download.key017');
      } else {
        this.button_text = this.$t('download.key016');
      }
    },
  },
};
</script>

<style lang="scss">
@import '../assets/styles/pages/downloadsTutorials.scss';
</style>
