<template>
  <section class="error">
    <h1>404</h1>
    <h2>頁面找不到</h2>
    <p>抱歉，我們無法找到您要訪問的頁面。</p>
    <a href="/">返回官網</a>
  </section>
</template>

<script>
export default {
  name: 'EmptyPage',
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
.error {
  width: 60%;
  margin: 0 auto;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    text-align: center;
    width: 100%;
    font-size: 100px;
    font-weight: 200;
    border-bottom: 1px solid #fff;
  }
}
</style>
