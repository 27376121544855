<template>
  <section class="wrapper download_page faq">
    <h1>{{ $t('faq.key005') }}</h1>
    <div class="block">
      <div class="faq-item" v-for="(faq, index) in faqs_download" :key="'download_' + index" :ref="'download_' + index">
        <div class="faq-question" @click="toggleAnswer('download_' + index)" :style="{ color: faq.open ? 'white' : '#A6BED9' }">
          <i class="bi bi-caret-right-fill faq-icon" :class="{ open: faq.open }"></i>
          {{ faq.question }}
        </div>
        <div v-if="faq.open">
          <div class="beatday-usage" v-for="(step, stepIndex) in faq.steps" :key="'step_' + stepIndex">
            <div class="img">
              <img :src="step.image" alt="" />
            </div>
            <div class="content">
              <h3>step {{ stepIndex + 1 }}</h3>
              <!-- Automatically number the steps -->
              <p>{{ step.content }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'FAQ',
  components: {},
  mounted() {},
  data() {
    return {
      faqs_download: [
        {
          question: this.$t('beatday-usage.key001'),
          steps: [
            {
              image: 'https://d1sldy51c97en8.cloudfront.net/image/BeatdayUsage/Step01_001.png',
              content: this.$t('beatday-usage.key006'),
            },
            {
              image: 'https://d1sldy51c97en8.cloudfront.net/image/BeatdayUsage/Step01_002.png',
              content: this.$t('beatday-usage.key007'),
            },
            {
              image: 'https://d1sldy51c97en8.cloudfront.net/image/BeatdayUsage/Step01_003.png',
              content: this.$t('beatday-usage.key008'),
            },
            {
              image: 'https://d1sldy51c97en8.cloudfront.net/image/BeatdayUsage/Step01_004.png',
              content: this.$t('beatday-usage.key009'),
            },
            {
              image: 'https://d1sldy51c97en8.cloudfront.net/image/BeatdayUsage/Step01_005.png',
              content: this.$t('beatday-usage.key010'),
            },
            {
              image: 'https://d1sldy51c97en8.cloudfront.net/image/BeatdayUsage/Step01_006.png',
              content: this.$t('beatday-usage.key011'),
            },
            {
              image: 'https://d1sldy51c97en8.cloudfront.net/image/BeatdayUsage/Step01_007.png',
              content: this.$t('beatday-usage.key012'),
            },
            {
              image: 'https://d1sldy51c97en8.cloudfront.net/image/BeatdayUsage/Step01_008.png',
              content: this.$t('beatday-usage.key013'),
            },
          ],
          open: false,
        },
        {
          question: this.$t('beatday-usage.key002'),
          steps: [
            {
              image: 'https://d1sldy51c97en8.cloudfront.net/image/BeatdayUsage/Step02_001.png',
              content: this.$t('beatday-usage.key014'),
            },
            {
              image: 'https://d1sldy51c97en8.cloudfront.net/image/BeatdayUsage/Step02_002.png',
              content: this.$t('beatday-usage.key015'),
            },
            {
              image: 'https://d1sldy51c97en8.cloudfront.net/image/BeatdayUsage/Step02_003.png',
              content: this.$t('beatday-usage.key016'),
            },
          ],
          open: false,
        },
        {
          question: this.$t('beatday-usage.key003'),
          steps: [
            {
              image: 'https://d1sldy51c97en8.cloudfront.net/image/BeatdayUsage/Step03_001.png',
              content: this.$t('beatday-usage.key017'),
            },
          ],
          open: false,
        },
        {
          question: this.$t('beatday-usage.key004'),
          steps: [
            {
              image: 'https://d1sldy51c97en8.cloudfront.net/image/BeatdayUsage/Step04_001.png',
              content: this.$t('beatday-usage.key018'),
            },
            {
              image: 'https://d1sldy51c97en8.cloudfront.net/image/BeatdayUsage/Step04_002.png',
              content: this.$t('beatday-usage.key019'),
            },
            {
              image: 'https://d1sldy51c97en8.cloudfront.net/image/BeatdayUsage/Step04_003.png',
              content: this.$t('beatday-usage.key020'),
            },
          ],
          open: false,
        },
        {
          question: this.$t('beatday-usage.key005'),
          steps: [
            {
              image: 'https://d1sldy51c97en8.cloudfront.net/image/BeatdayUsage/Step05_001.png',
              content: this.$t('beatday-usage.key021'),
            },
            {
              image: 'https://d1sldy51c97en8.cloudfront.net/image/BeatdayUsage/Step05_002.png',
              content: this.$t('beatday-usage.key022'),
            },
            {
              image: 'https://d1sldy51c97en8.cloudfront.net/image/BeatdayUsage/Step05_003.png',
              content: this.$t('beatday-usage.key023'),
            },
            {
              image: 'https://d1sldy51c97en8.cloudfront.net/image/BeatdayUsage/Step05_004.png',
              content: this.$t('beatday-usage.key024'),
            },
          ],
          open: false,
        },
      ],
    };
  },
  props: {},
  computed: {},
  methods: {
    toggleAnswer: function (key) {
      this.faqs_download.forEach((faq, index) => {
        const faqKey = 'download_' + index;

        if (faqKey === key) {
          faq.open = !faq.open;

          if (faq.open) {
            this.$nextTick(() => {
              const targetElement = this.$refs[faqKey];

              if (targetElement) {
                const element = Array.isArray(targetElement) ? targetElement[0] : targetElement;

                const elementTop = element.getBoundingClientRect().top + window.pageYOffset;

                window.scrollTo({
                  top: elementTop - 150,
                  behavior: 'smooth',
                });
              }
            });
          }
        } else {
          faq.open = false;
        }
      });
    },
  },
};
</script>

<style lang="scss"></style>
