<template>
  <app-header></app-header>
  <section class="products">
    <swiper :loop="true" :modules="modules" :pagination="{ clickable: true }" :slides-per-view="1" :autoplay="{ delay: 3000, disableOnInteraction: false }">
      <swiper-slide v-for="(item, index) in slides" :key="index" class="slide-item">
        <a v-if="item.type === 'link'" :href="item.url" target="_blank">
          <img :src="item.src" :alt="item.alt" />
        </a>
        <router-link v-else :to="`/${currentLanguage}/${item.route}`" target="_blank">
          <img :src="item.src" :alt="item.alt" />
        </router-link>
      </swiper-slide>
      <div class="swiper-pagination"></div>
    </swiper>
    <h1 class="title">{{ $t('products.key001') }}</h1>
    <div class="products-list">
      <div class="product-item" v-for="product in products.data" :key="product.id">
        <div class="product-info" @click="viewProduct(product.id)">
          <img :src="product.image" alt="" class="" />
          <div class="text-info">
            <div class="time">
              <p>
                <i class="bi bi-calendar-week"></i>
                <span>{{ product.time }}</span>
              </p>
            </div>
            <div class="text">
              <h2 class="title" :title="product.name">
                {{ product.name }}
              </h2>
            </div>
          </div>
          <button class="go-to">{{ $t('products.key002') }}</button>
        </div>
      </div>
    </div>
  </section>
  <app-footer></app-footer>
  <Loading v-show="loading"></Loading>
</template>

<script>
import AppHeader from '@/components/Header.vue';
import AppFooter from '@/components/Footer.vue';
import { getProducts } from '@/api/cartService.js';
import Loading from '@/components/Loading.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const baseURL = process.env.URL_DOMAIN;

export default {
  name: 'EmptyPage',
  components: {
    'app-header': AppHeader,
    'app-footer': AppFooter,
    Loading,
    Swiper,
    SwiperSlide,
  },
  props: {},
  setup() {
    return {
      modules: [Autoplay, Navigation, Pagination],
    };
  },
  data() {
    return {
      slides: [
        {
          type: 'router',
          route: 'event/AMW%3C3',
          src: 'https://d1sldy51c97en8.cloudfront.net/image/Products/zh_tw/02_MiruWorderland/IndexBanner/miru_wonderland1920%20x%20575.jpg',
          alt: 'VDay誕生日',
        },
        {
          type: 'link',
          url: `${baseURL}/MizukiLin/`,
          src: 'https://d1sldy51c97en8.cloudfront.net/image/Products_banner/zh_tw/MizukiLin/Banner_MZK.png',
          alt: '林襄沈浸式寫真',
        },
      ],
      products: [],
      loading: false,
    };
  },
  async mounted() {
    await this.fetchProducts();
  },
  methods: {
    async fetchProducts() {
      try {
        this.loading = true;
        const langSetting = this.currentLanguage === 'zh_tw' ? 'zh-tw' : this.currentLanguage;
        const response = await getProducts(langSetting);

        this.products = response.data;
        const productsData = this.products.data;

        if (productsData) {
          productsData.forEach((item) => {
            if (item.startTime && item.endTime) {
              item.time = this.formatTimeRange(item.startTime, item.endTime);
            }
          });
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        this.loading = false;
      }
    },
    // time format
    formatTimeRange(startTime, endTime) {
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);

      const formatDate = (date) => {
        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const hh = String(date.getHours()).padStart(2, '0');
        const min = String(date.getMinutes()).padStart(2, '0');

        const timeZone = date.toString().match(/\(([^)]+)\)$/)[1];
        return `${yyyy}/${mm}/${dd} ${hh}:${min} (${timeZone})`;
      };

      const formattedStart = formatDate(startDate);
      const formattedEnd = formatDate(endDate);

      return `${formattedStart} ~ ${formattedEnd}`;
    },
    viewProduct(productId) {
      this.$router.push({
        path: `/${this.currentLanguage}/product-info/${productId}`,
      });
    },
  },
};
</script>

<style lang="scss">
@import '../../assets/styles/pages/products/products.scss';
</style>
