<template>
  <app-header></app-header>
  <div class="cart">
    <component
      :cart-data="cartData"
      :is="currentComponent"
      :phone-server-check="phoneServerCheck"
      @update-data="handleCartDataUpdate"
      @submit-order="submitOrder"
      @next="nextStep"
      @prev="prevStep"
    ></component>
  </div>
  <app-footer></app-footer>
  <Loading v-show="loading"></Loading>
</template>

<script>
import AppHeader from '@/components/Header.vue';
import AppFooter from '@/components/Footer.vue';
import CartComponent from '@/components/cartCheckout/CartComponentStep01.vue';
import PaymentComponent from '@/components/cartCheckout/PaymentComponentStep02.vue';
import { querySessionPacks, createOrder, createAuthOrder } from '@/api/cartService.js';
import { getUserInfo } from '@/api/apiService';
import { getCartItems } from '@/localStorage/cartStorage.js';
import Loading from '@/components/Loading.vue';

export default {
  name: 'ShoppingCart',
  components: {
    'app-header': AppHeader,
    'app-footer': AppFooter,
    CartComponent,
    PaymentComponent,
    Loading,
  },
  data() {
    return {
      phoneServerCheck: true,
      step: 0,
      cartData: {
        orderItems: [],
        totalAmount: 0,
        totalDiscount: 0,
        finalAmount: 0,
        name: '',
        phone: '',
        email: '',
        address: {
          county: '',
          zipCode: '',
          district: '',
          addressDetail: '',
        },
        hasPhysicalItem: null,
        shipping: null,
      },
      loading: false,
    };
  },
  async created() {
    await this.getCartData();
    if (this.jwt) {
      await this.getUserInfo();
    }
  },
  computed: {
    jwt() {
      return this.$store.state.jwt;
    },
    currentComponent() {
      switch (this.step) {
        case 0:
          return 'CartComponent';
        case 1:
          return 'PaymentComponent';
        default:
          return 'CartComponent';
      }
    },
    hasPhysicalItem() {
      return this.cartData.orderItems.some((item) => item.hasPhysicalItem === true);
    },
  },
  watch: {
    'cartData.orderItems': {
      handler(newItems) {
        const { totalAmount, totalDiscount, finalAmount } = newItems.reduce(
          (result, product) => {
            const amount = product.originalPrice || product.price;
            const discount = product.originalPrice ? product.originalPrice - product.price : 0;

            return {
              totalAmount: result.totalAmount + amount * product.quantity,
              totalDiscount: result.totalDiscount + discount * product.quantity,
              finalAmount: result.finalAmount + product.price * product.quantity,
            };
          },
          {
            totalAmount: 0,
            totalDiscount: 0,
            finalAmount: 0,
          },
        );
        this.cartData.totalAmount = totalAmount;
        this.cartData.totalDiscount = totalDiscount;
        this.cartData.finalAmount = finalAmount;
      },
      deep: true,
    },
    jwt() {
      if (this.jwt) {
        this.getUserInfo();
      }
    },
    hasPhysicalItem(value) {
      this.cartData.hasPhysicalItem = this.hasPhysicalItem;
      if (!value) {
        this.cartData.shipping = null;
      }
    },
  },
  methods: {
    async getUserInfo() {
      try {
        this.loading = true;

        const userData = await getUserInfo(this.jwt);

        this.cartData.email = userData.contactEmail;
        this.cartData.name = userData.name;
        this.cartData.address = userData.address;
        this.cartData.phone = userData.phone;
      } catch (error) {
        if (error?.response?.status === 400) {
          // todo: redirect?
          alert('Failed to get user info');
          console.error('account not found');
        } else {
          console.error('error', error.message);
        }
      } finally {
        this.loading = false;
      }
    },
    // get from localStorage
    async getCartData() {
      const cartItems = getCartItems() || [];

      if (Object.keys(cartItems).length === 0) {
        this.$nextTick(() => {
          this.checkCartEmpty();
        });
        return;
      }
      await this.fetchCartItems(cartItems);
    },
    //get from API cartItems Data
    async fetchCartItems(cartItems) {
      try {
        this.loading = true;
        const langSetting = this.currentLanguage === 'zh_tw' ? 'zh-tw' : this.currentLanguage;
        const response = await querySessionPacks(cartItems, langSetting);
        this.fetchCartItemsData = response.data.data;
        if (this.fetchCartItemsData) {
          this.fetchCartItemsData.forEach((item) => {
            if (item.startTime && item.endTime) {
              item.time = this.formatTimeRange(item.startTime, item.endTime);
            }
          });
        }

        const fetchedCarItemsWithQuantity = this.setQuantityToFetchedCartItem(cartItems, this.fetchCartItemsData);
        this.cartData.orderItems = fetchedCarItemsWithQuantity;
      } catch (error) {
        console.error('Error fetching CartItems:', error);
      } finally {
        this.loading = false;
      }
    },
    setQuantityToFetchedCartItem(cartItems, fetchCartItemsData) {
      const cartItemsMap = new Map();
      cartItems.forEach((item) => {
        const key = `${item.productId}-${item.sessionId}-${item.packId}`;
        cartItemsMap.set(key, item.quantity);
      });

      if (Array.isArray(fetchCartItemsData)) {
        fetchCartItemsData.forEach((data) => {
          const key = `${data.productId}-${data.sessionId}-${data.packId}`;
          if (cartItemsMap.has(key)) {
            data.quantity = cartItemsMap.get(key); // 設置數量
          } else {
            data.quantity = 0;
          }
        });

        return fetchCartItemsData;
      } else {
        console.error('fetchCartItemsData is not an array:', fetchCartItemsData);
        this.fetchCartItemsData = [];
      }
    },
    // time format
    formatTimeRange(startTime, endTime) {
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);

      const formatDate = (date) => {
        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const hh = String(date.getHours()).padStart(2, '0');
        const min = String(date.getMinutes()).padStart(2, '0');

        const timeZone = date.toString().match(/\(([^)]+)\)$/)[1];
        return `${yyyy}/${mm}/${dd} ${hh}:${min} (${timeZone})`;
      };

      const formattedStart = formatDate(startDate);
      const formattedEnd = formatDate(endDate);

      return `${formattedStart} ~<br/> ${formattedEnd}`;
    },
    updateQuantity(itemId, quantity) {
      this.cartService.updateCartItemQuantity(itemId, quantity);
    },
    removeFromCart(itemId) {
      this.cartService.removeFromCart(itemId);
    },
    nextStep() {
      this.step += 1;
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    prevStep() {
      this.step = 0;
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    updateData(newData) {
      this.cartData = newData;
    },
    handleCartDataUpdate(updatedCartData) {
      this.cartData = updatedCartData;
    },
    checkCartEmpty() {
      if (this.cartData.orderItems.length === 0) {
        this.loading = false;
        alert(this.$t('shopping-cart.key020'));
        this.$router.push(`/${this.currentLanguage}/`);
      }
    },
    async submitOrder() {
      let response;
      try {
        this.loading = true;
        const langSetting = this.currentLanguage === 'zh_tw' ? 'zh-tw' : this.currentLanguage;

        const sendBody = {
          ...this.cartData,
          phone: this.cartData.phone.replace(/\s/g, ''),
        };

        response = this.jwt ? await createAuthOrder(sendBody, langSetting, this.jwt) : await createOrder(sendBody, langSetting);

        if (response.data) {
          this.submitToPaymentGateway(response.data.data);
        }

        this.phoneServerCheck = true;
      } catch (error) {
        console.error('Error submitting order:', error);
        if (error?.response?.status === 422 && error?.response?.data?.status?.message?.includes('invalid phone number')) {
          this.phoneServerCheck = false;
        }
        alert(this.$t('shopping-cart.key039'));
      } finally {
        this.loading = false;
      }
    },
    submitToPaymentGateway(data) {
      const form = document.createElement('form');
      form.method = 'post';
      form.action = process.env.NEWEB_PAY_URL;

      const fields = [
        { name: 'MerchantID', value: data.merchantId },
        { name: 'Version', value: data.version },
        { name: 'TradeInfo', value: data.tradeInfo },
        { name: 'TradeSha', value: data.tradeSha },
      ];

      fields.forEach((field) => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = field.name;
        input.value = field.value;
        form.appendChild(input);
      });

      document.body.appendChild(form);
      form.submit();
    },
  },
};
</script>

<style lang="scss">
@import '../assets/styles/pages/products/cart.scss';
</style>
