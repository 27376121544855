<template>
  <section class="privacy_page contact_us">
    <h1>{{ $t('about-us.key004') }}</h1>
    <p v-html="formattedKey001"></p>
    <contact-us></contact-us>
  </section>
</template>
<script>
import ContactUs from '@/components/ContactUs.vue';
export default {
  name: 'LegalPage',
  components: {
    'contact-us': ContactUs,
  },
  mounted() {},
  data() {
    return {};
  },
  props: {},
  computed: {
    formattedKey001() {
      let rawMessage = this.$t('contact-us.key001', {
        faqLink: `<router-link :to="'/${this.currentLanguage}/faq'">${this.$t(
          'contact-us.key002'
        )}</router-link>`,
        LineLink: `<a href='https://line.me/R/ti/p/@485ibcgx?oat__id=29053'>${this.$t(
          'contact-us.key003'
        )}</a>`,
      });

      return rawMessage.replace(/\\n/g, '<br/>');
    },
  },
  methods: {},
};
</script>

<style lang="scss"></style>
