import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router';
import store from './vuex';
import i18n from './assets/i18n';
import { globalMixins, StatusCodes } from './assets/i18n/routerLinkLang.js'; // 假設這是您的全局混入
import './assets/styles/reset.scss';
import './assets/styles/global.scss';
import vueCountryRegionSelect from 'vue3-country-region-select';

// 創建 vue 應用實例
const app = createApp(App);
const globalData = {
  // 全局變量
  Version: 'release',
};

// 使用 i18n
app.use(i18n);

app.config.globalProperties.$globalData = globalData;
app.config.globalProperties.$statusCodes = StatusCodes;

// 使用路由
app.use(router);

// 使用 Vuex
app.use(store);

// 全局混入，用于在初始化时设置默认语言
app.mixin(globalMixins);

app.use(vueCountryRegionSelect);
// 挂载应用
app.mount('#app');
